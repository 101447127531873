import React, { useState, useEffect, useRef } from "react";
import "./EditTasks.css";
import WebApp from "@twa-dev/sdk";
import axios from 'axios';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB'; 
registerLocale('en-GB', enGB);

const useAutoResizeTextarea = (textareaRef, value) => {
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    }
  }, [value, textareaRef]); // Update height when value or ref changes
};


const EditTasks = () => {
  const { taskId: paramTaskId } = useParams(); 
  const location = useLocation();
  const navigate = useNavigate();

  const taskId = location.state?.taskId || paramTaskId;

  const [hasChanges, setHasChanges] = useState(false);

  const [task, setTask] = useState({});
  const [user, setUser] = useState({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDeadline, setIsEditingDeadline] = useState(false);
  const [newName, setNewName] = useState('');
  const [newDeadline, setNewDeadline] = useState(null);
  const [selectedProject, setSelectedProject] = useState(''); 
  const [selectedExecutors, setSelectedExecutors] = useState([]);
  const [selectedExecutorIds, setSelectedExecutorIds] = useState([]);
  const [isImportant, setIsImportant] = useState(false);
  const [chats, setChats] = useState([]);
  const [members, setMembers] = useState([]);
  const [chatName, setChatName] = useState('Unnamed Chat');
  const [comments, setComments] = useState([]);
  const taskInputRef = useRef(null);
  useAutoResizeTextarea(taskInputRef, newName);
  const apiUrl = process.env.NODE_ENV === 'production' 
    ? 'https://tasks.ledokol.it/api' 
    : 'http://localhost:5000';

  const checkIfEdited = () => {
    if (
      task &&
      (
        newName !== task.name ||
        newDeadline?.toISOString() !== new Date(task.deadline)?.toISOString() ||
        selectedProject !== task.chat_id ||
        JSON.stringify(selectedExecutors) !== JSON.stringify(task.executors) ||
        isImportant !== task.important
      )
    ) {
      setHasChanges(true); 
    } else {
      setHasChanges(false);
    }
  };
  const fetchComments = async (taskId) => {
    try {
      const response = await axios.get(`${apiUrl}/get-task-comments/${taskId}`);
      setComments(response.data.comments || []);
    } catch (error) {
      console.error('Error fetching task comments:', error);
    }
  };
  const fetchUserChats = async (userId) => {
    if (!userId) return;
    try {
      const response = await axios.get(`${apiUrl}/get-user-chats/?userId=${userId}`);
      if (response.status === 200) {
        const chatsArray = response.data.chats || [];
        setChats(chatsArray);
      } else {
        console.error("Failed to fetch user chats");
      }
    } catch (error) {
      console.error("Error fetching user chats:", error);
    }
  };

  const fetchTask = async () => {
    if (!taskId) {
      console.error('Task ID is undefined');
      return;
    }
  
    try {
      const response = await axios.get(`${apiUrl}/get-task/${taskId}`);
      const taskData = response.data;
      setTask(taskData);
      setNewName(taskData.name);
      setNewDeadline(taskData.deadline ? new Date(taskData.deadline) : null); 
      setSelectedProject(taskData.chat_id); 
      setSelectedExecutors(taskData.executors || []);
      setSelectedExecutorIds(taskData.executor_ids || []);
      setIsImportant(taskData.important || false);
      
      const currentChat = chats.find(chat => chat.id === taskData.chat_id);
      setChatName(currentChat ? currentChat.name : 'Unnamed Chat');
      await fetchComments(taskId);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn('Task not found, setting default task');
        setTask({
          id: taskId,
          name: 'Default Task Name',
          description: 'No description provided',
          deadline: null,
          chat_id: 'No',
        });
        setChatName('Unnamed Chat');
      } else {
        console.error('Error fetching the task:', error);
      }
    }
  };

  useEffect(() => {
    const tg = WebApp;
    tg.ready();

    const fetchProfilePhoto = async (userId) => {
      try {
        const response = await axios.get(`${apiUrl}/get-profile-photo?userId=${userId}`);
        return response.data.photoUrl || "/img/default-profile.png";
      } catch (error) {
        console.error("Error fetching profile photo:", error);
        return "/img/default-profile.png";
      }
    };

    const initUser = async () => {
      const userId = tg.initDataUnsafe?.user?.id;
      const photoUrl = await fetchProfilePhoto(userId);

      setUser({
        username: tg.initDataUnsafe?.user?.username || "Username",
        profilePhoto: photoUrl,
        firstName: tg.initDataUnsafe?.user?.first_name || "User",
        user_id: userId,
      });
      return userId;
    };

    const loadData = async () => {
      const userId = await initUser();
      await fetchUserChats(userId); 
      await fetchTask(); 
    };

    loadData();
  }, []); // Empty dependency array ensures this runs only once

  // Set up the BackButton after task and chats have been loaded
  useEffect(() => {
    if (task && chats.length > 0) {
      const from = location.state?.from; 
      const tg = WebApp;
      tg.BackButton.show();
  
      tg.BackButton.onClick(() => {
        if (from === 'main' ) {
          navigate('/');
        } else if (from === 'main2'){
          const chat = chats.find(chat => chat.id === task.chat_id);
          const chatName = chat.name || 'Unnamed Chat';
          navigate(`/chat/${task.chat_id}`, {
            state: {
              chatId: task.chat_id,
              chatName: chatName,
            },
          });
        } else {
          navigate('/');
        }
      });
    }
  }, [task, chats, navigate, location.state?.from]);

  useEffect(() => {
    if (selectedProject) {
      const fetchMembers = async () => {
        try {
          const response = await axios.get(`${apiUrl}/get-chat-members?chatId=${selectedProject}`);
          setMembers(response.data.members || []); 
        } catch (error) {
          console.error('Error fetching chat members:', error);
        }
      };
      fetchMembers();
    }
  }, [selectedProject]);

  useEffect(() => {
    checkIfEdited();
  }, [newName, newDeadline, selectedProject, selectedExecutors, isImportant]);

  const handleSave = async () => {
    if (!hasChanges) return; 
    try {
      const formattedDeadline = newDeadline
        ? newDeadline.toISOString().split("T")[0]
        : null;
      const updatedTask = {
        name: newName,
        deadline: formattedDeadline || null, 
        executors: selectedExecutors,
        executor_ids: selectedExecutorIds,
        important: isImportant,
        completed: task.completed || false,
        message_id: task.message_id || null,
        chat_id: selectedProject || null,
        user_id: user.user_id,
      };
  
      await axios.put(`${apiUrl}/update-task/${taskId}`, updatedTask);
      await fetchTask(); 
      setHasChanges(false); 
      console.log("Task updated successfully!");
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };

  const handleDeleteTask = async () => {
    try {
      const response = await axios.delete(`${apiUrl}/delete-task/${taskId}`);
      if (response.status === 200) {
        navigate(-1);  
        console.log("Task deleted successfully!");
      } else {
        console.error('Failed to delete task');
      }
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleAddToChat = () => {
    const tg = WebApp;
    tg.openTelegramLink('https://t.me/task_trackerfj_bot?startgroup=true');
  };

  if (!task) {
    return <div>Loading...</div>;
  }

  return (
    <div className="root">
      <div className="view">
        <div className="frame">
          <div className="div">
            <img className="image" alt="Profile" src={user.profilePhoto} />
            <div className="text-wrapper">{user.username}</div>
          </div>
          <div className="add-to-chat" onClick={handleAddToChat}>
            <img className="img" alt="Add Icon" src="/img/addicon.svg" />
            <div className="frame-2">
              <div className="text-wrapper-2">Add to chat</div>
            </div>
          </div>
        </div>
      </div>

      <div className="groups-2">
        {/* Task Name */}
        <div className="task-container" onClick={() => setIsEditingName(true)}>
          <textarea
            type="text"
            className="task-input task-title-edit" 
            rows="1"
            placeholder="Enter task name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={() => setIsEditingName(false)}
            autoFocus
            ref={taskInputRef}
          />

        </div>
      </div>

      <div className="groups-2">
        {/* Deadline */}
        <div className="task-edit-container" onClick={() => setIsEditingDeadline(true)}>
          <div className="task-edit-title">
            <img className="task-icon" src="/icons/CalendarActiveIcon.svg" alt="Calendar Icon" />
            <div className="task-label">Deadline</div>
          </div>
          <div className="task-edit-desc">
            {isEditingDeadline ? (
              <DatePicker
                selected={newDeadline}
                onChange={(date) => setNewDeadline(date)}
                locale="en-GB"
                onBlur={() => setIsEditingDeadline(false)}
                dateFormat="dd/MM/yyyy"
                autoFocus
              />
            ) : (
              <div className="task-value">
                {newDeadline ? newDeadline.toLocaleDateString() : 'No date'}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="groups-2">
        {/* Project */}
        <div className="task-edit-container">
          <div className="task-edit-title">
            <img className="task-icon" src="/icons/FolderIcon.svg" alt="Project Icon" />
            <div className="task-label">Project</div>
          </div>
          <div className="task-edit-desc">
            <select 
              className="task-input project-input"
              value={selectedProject} 
              onChange={(e) => setSelectedProject(e.target.value)}
            >
              <option value="">Select Project</option>
              {chats.length > 0 ? (
                chats.map((chat) => (
                  <option key={chat.id} value={chat.id}>
                    {chat.name}
                  </option>
                ))
              ) : (
                <option value="">No Projects Available</option>
              )}
            </select>
          </div>
        </div>
      </div>

      {members.length > 0 && (
        <div className="groups-2">
          <div className="task-edit-container">
            <div className="task-edit-title">
              <img className="task-icon" src="/icons/Executor.svg" alt="User Icon" />
              <div className="task-label">Executors</div>
            </div>
            <div className="task-edit-desc">
              <select

                className="task-input executor-input"
                value={selectedExecutors}
                onChange={(e) => {
                  const selectedOptions = Array.from(e.target.selectedOptions);
                  const usernames = selectedOptions.map(option => option.value);
                  const ids = selectedOptions.map(option => option.getAttribute('data-id'));
                  setSelectedExecutors(usernames);
                  setSelectedExecutorIds(ids);
                }}
              >
                {members.map((member) => (
                  <option key={member.id} value={member.username} data-id={member.id}>
                    {member.username}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {/* <div className="groups-2">
        <div className="task-edit-container">
          <div className="task-edit-title">
            <img className="task-icon" src="/icons/ImportantIcon.svg" alt="Important Icon" style={{
                  width: "18px",
                  height: "18px",}}
                  />
            <div className="task-label">Important</div>
          </div>
          <div className="task-edit-desc">
            <label className="important-checkbox-label">
              <input
                type="checkbox"
                checked={isImportant}
                onChange={(e) => setIsImportant(e.target.checked)}
              />
            </label>
          </div>
          
        </div>
      </div> */}
      <div className="groups-2">
        <div className="task-edit-container">
          <div className="task-edit-title">
            <img className="task-icon" src="/icons/CommentIcon.svg" alt="Comment Icon" />
            <div className="task-label">Comments</div>
          </div>
          <div className="comments-section">
            {comments.length > 0 ? (
              comments.map((comment, index) => (
                <div key={index} className="comment-item">
                  <div className="comment-username">{comment.user}</div>
                  <div className="comment-text">{comment.text}</div>
                </div>
              ))
            ) : (
              <div className="no-comments">No comments yet.</div>
            )}
          </div>
        </div>
      </div>       
      {/* Save Button */}
      <div 
        className={`save-button ${hasChanges ? 'active' : 'disabled'}`} 
        onClick={hasChanges ? handleSave : null}
      >
        Save
      </div>
      <div 
        className="delete-button" 
        onClick={handleDeleteTask}
      >
        Delete task
      </div>
    </div>
  );
};

export default EditTasks;
