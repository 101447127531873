import React, { useRef, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox'; 
const Task = ({ task, chats, executorAvatars, handleTaskClick, handleTaskComplete, formatDeadline, mainPage = true, completed = false, showAvatars = true }) => {
  const getFirstCharacter = (name) => {
    if (!name) return '?'; 
    return [...name][0];
  };
  
  const executorIds = Array.isArray(task.executor_ids) ? task.executor_ids : [];
  const executors = Array.isArray(task.executors) ? task.executors : [];

  return (
    <div
      key={task.id}
      className="task-container"
      onClick={() => handleTaskClick(task)}
    >
      <div className="checkbox-task">
        <Checkbox
          checked={task.completed}
          onClick={(e) => e.stopPropagation()} // Предотвращаем всплытие
          onChange={() => {
            handleTaskComplete(task.id);
          }}
          inputProps={{ 'aria-label': 'controlled' }} 
          sx={{ 
            padding: 0,
            color: 'white',  // Цвет чекбокса, когда он не отмечен
            '&.Mui-checked': {
            color: 'white', // Цвет чекбокса, когда он отмечен
            } }
          }
        />
      </div>
      <div className="task-content">
        <div className="task-title">
          {task.name}
        </div>
        <div className="task-meta">
          {!completed && task.important && <img src="/icons/Important.svg" alt="Important" className="important-icon" style={{ width: "15px", height: "15px" }} />}
          {mainPage && (() => {
              if (!task.chat_id) {
                  return null
              }

              const chat = chats.find(chat => chat.id === task.chat_id);
              
              if (chat) {
              return <span className="task-project">{chat.name}</span>;
              } else {        
              return <span className="task-project">Unknown chat</span>;
              }
          })()}
          {task.deadline && (
              <div className="task-deadline">
              <img
                  src="/img/RedFlag.svg"
                  alt="RedFlag"
                  className="red-flag"
              />
              <span className="task-date">
                  {formatDeadline(new Date(task.deadline))}
              </span>
              </div>
          )}
        </div>
      </div>
      { showAvatars && !completed && !mainPage && executorIds.length > 0 && (
            <div className="executor-avatars">
              {executorIds.map((executorId, index) => {
                const avatarUrl = executorAvatars[executorId];
                if (!avatarUrl && !executors[index]) {
                  return null; // Если нет аватара и нет имени, не отображаем элемент
                }
                return avatarUrl ? (
                  <img
                      key={executorId}
                      className="executor-avatar"
                      src={avatarUrl}
                      alt="Executor Avatar"
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        marginLeft: "0px",
                        marginBottom: "5px",
                      }}
                    />
                ) : (
                  <div
                      key={executorId}
                      className="executor-placeholder"
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        backgroundColor: "#ccc",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "0px",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                    {executors[index] ? getFirstCharacter(executors[index]) : "?"}
                  </div>
                );
              })}
            </div>
          )}
    </div>
  );
};

export default Task;
